<template>
	<ion-fab
		v-if="whappyClientConfig.name == 'Yoube' && !askingConfirmation"
		vertical="bottom"
		horizontal="end"
	>
		<!-- <ion-fab-button
          @click="openModalNewKpiChat"
          class="ion-margin-end ion-margin-bottom"
        >
          <ion-icon :icon="cart"></ion-icon>
        </ion-fab-button> -->
		<ion-fab-button
			@click="
				() => {
					$router.push('/catalogo');
					closeModal();
				}
			"
			class="ion-margin-end ion-margin-bottom"
		>
			<ion-icon
				class="pb-1"
				:icon="add"
			></ion-icon>
		</ion-fab-button>
	</ion-fab>
	<master-layout
		smallTitle="il tuo"
		bigTitle="Carrello"
	>
		<div
			v-if="!askingConfirmation"
			class="page"
		>
			<div>
				<div class="resume">
					<!-- <h3>Il tuo carrello</h3> -->
					<div
						v-if="currentCart.items.length == 0"
						class="p-1 mb-4"
					>
						il carrello è vuoto...
					</div>
					<div v-else>
						<div
							class="item"
							v-for="item in currentCart.items"
							:key="item.name"
						>
							<span
								@click="removeItem(item)"
								class="text-color-primary"
								><ion-icon :icon="closeCircleOutline"></ion-icon
							></span>
							<span class="font-bold">{{ item.name }}</span>
							<span class="font-bold text-color-primary">{{
								item.price.toLocaleString("it", {
									style: "currency",
									currency: "EUR",
								})
							}}</span>
							<div class="counter">
								<span
									@click="substractOne(item)"
									class="remove"
									>-</span
								>
								<span class="actual">{{ item.number }}</span>
								<span
									@click="addOne(item)"
									class="add"
									>+</span
								>
							</div>
						</div>
					</div>

					<div class="messages-container">
						<div class="message mb-2">
							<span>Promozione attiva</span>
							<span>{{ currentCart.has_promo ? "SI" : "NO" }}</span>
						</div>

						<div class="message mb-2">
							<span>Sconto / Codice promozionale</span>
							<span>{{
								currentCart.discount
									? currentCart.discount.toLocaleString("it", {
											style: "currency",
											currency: "EUR",
									  })
									: "NO"
							}}</span>
						</div>
					</div>

					<div class="flex justify-content-between mt-4 pt-4 pb-2">
						<div
							v-if="currentCart.customer.id"
							class="dettagli-cliente flex justify-content-center align-items-top text-10"
						>
							<ion-icon
								@click="svuotaCarrello"
								style="padding: 0 0.7rem 0.1rem 0; font-size: 2rem"
								:icon="closeCircleOutline"
							></ion-icon>
							<div class="flex flex-column">
								<div class="mb-1">
									<span>Cliente:</span>
									<span class="font-bold ml-2">{{
										currentCart.customer.ragioneSociale
									}}</span>
								</div>
								<div
									class="mb-1"
									v-if="currentCart.customer.partitaIva"
								>
									<span>P.IVA: </span>
									<span class="font-bold ml-2">{{
										currentCart.customer.partitaIva
									}}</span>
								</div>
								<div
									class="mb-1"
									v-if="currentCart.customer.citta"
								>
									<span>Città: </span>
									<span class="font-bold ml-2">{{
										currentCart.customer.citta
									}}</span>
								</div>

								<div class="flex flex-column">
									Condizioni di pagamento:
									<ion-select
										class="ion-no-padding font-bold"
										placeholder="nessuna selezione..."
										interface="popover"
										v-model="currentCart.payment_condition"
										@ionChange="handleCondizioniPagamentoChange($event)"
									>
										<ion-select-option value="30">30 gg/fm</ion-select-option>
										<ion-select-option value="60">60 gg/fm</ion-select-option>
										<ion-select-option value="90">90 gg/fm</ion-select-option>
										<ion-select-option value="3060"
											>30-60 gg/fm</ion-select-option
										>
										<ion-select-option value="306090"
											>30-60-90 gg/fm</ion-select-option
										>
									</ion-select>
								</div>
							</div>
						</div>
						<div
							v-else
							class="text-10 font-bold"
						>
							ERRORE - NESSUN CLIENTE SELEZIONATO
						</div>

						<div class="totali-parziali">
							<span
								>Subtotale:
								<span class="text-color-primary">{{
									currentCart.tot_price.toLocaleString("it", {
										style: "currency",
										currency: "EUR",
									})
								}}</span></span
							>
							<span class="mt-1"
								>IVA:
								<span class="text-color-primary">{{
									(currentCart.tot_price * 0.22).toLocaleString("it", {
										style: "currency",
										currency: "EUR",
									})
								}}</span></span
							>
							<span class="font-bold mt-2">
								Totale
								<span class="text-color-primary">{{
									(currentCart.tot_price * 1.22).toLocaleString("it", {
										style: "currency",
										currency: "EUR",
									})
								}}</span></span
							>
						</div>
					</div>
				</div>
				<div
					class="bottoni-finali flex flex-column justify-content-center align-items-center my-4"
				>
					<button
						v-if="loadingConferma"
						class="p-d w-full button-primary mb-2"
						disabled
					>
						Attendi grazie...
					</button>
					<button
						v-else
						class="p-d w-full button-primary mb-2"
						@click="askConfirmation()"
					>
						Conferma ordine
					</button>
					<button
						class="p-d w-full button-primary"
						@click="confermaOrdine(false)"
					>
						Invia ordine da confermare
					</button>
				</div>
				<!-- {{ store.shoppingCart }} -->
			</div>
		</div>
		<div
			v-else
			class="flex flex-column justify-content-center align-items-center ion-margin-top ino-padding-top pt-6 mt-6"
		>
			<h2>Vuoi confermare l'ordine?</h2>
			<div class="w-full p-2 flex justify-content-between align-items-center">
				<button
					@click="askingConfirmation = false"
					class="p-d button-primary"
				>
					Annulla
				</button>
				<button
					@click="confermaOrdine(true)"
					class="p-d button-primary"
				>
					Conferma
				</button>
			</div>
		</div>
	</master-layout>
</template>

<script setup>
	import { ref } from "vue";
	import { closeCircleOutline, add } from "ionicons/icons";
	import { useRouter } from "vue-router";
	import { useStore } from "vuex";
	import ApiService from "../../common/service.api";
	import { IonSelect, IonFab, IonFabButton, IonSelectOption } from "@ionic/vue";

	const store = useStore();
	const router = useRouter();
	const currentCart = ref(store.getters["shoppingCart/getCurrentCart"]);
	console.log(
		"🚀 ~ file: ShoppingCart.vue:205 ~ currentCart:",
		currentCart.value
	);

	const loadingConferma = ref(false);

	function handleCondizioniPagamentoChange(event) {
		currentCart.value.payment_condition = event.detail.value;
	}

	const currentUser = ref({});
	const id_logged_user = ref(0);
	function getLoggedUser() {
		if (store.getters["user/getUserData"]) {
			currentUser.value = store.getters["user/getUserData"];
			id_logged_user.value = store.getters["user/getUserData"].id;
		} else {
			// router.push("/perform-logout")
			console.error("user not found");
		}
	}

	getLoggedUser();
	const idOrder = ref(null);
	const askingConfirmation = ref(false);
	function askConfirmation() {
		askingConfirmation.value = true;
	}
	function confermaOrdine(isConfirmation) {
		idOrder.value = null;
		loadingConferma.value = true;

		let objectToPost = {
			user_id: id_logged_user.value,
			client_id: currentCart.value.customer.id,
			n_items: currentCart.value.n_items,
			tot_price: currentCart.value.tot_price,
			discount: currentCart.value.discount,
			payment_condition: currentCart.value.payment_condition,
			confirmed: isConfirmation,
			items: [],
		};

		let itemsToPost = currentCart.value.items.map((item) => {
			return {
				idProdotto: item.idProdotto,
				idBoundle: item.idBoundle,
				price: item.price,
				promo: item.discount,
				number: item.number,
			};
		});

		Promise.all(itemsToPost).then(() => {
			objectToPost.items = itemsToPost;

			ApiService.post("Ecommerce/AddOrder", objectToPost)
				.then((res) => {
					console.log("🚀 ~ file: ShoppingCart.vue:215 ~ .then ~ res:", res);
					idOrder.value = res.data;
				})
				.catch((err) => {
					router.go(-1);
				})
				.finally(() => {
					loadingConferma.value = false;

					isConfirmation
						? router.push("/conferma-ordine")
						: router.push(`/invia-ordine/${idOrder.value}`);
				});
		});
	}

	function addOne(item) {
		store.dispatch("shoppingCart/addItem", item);
	}

	function removeItem(item) {
		store.dispatch("shoppingCart/removeItem", item);
	}

	function substractOne(item) {
		store.dispatch("shoppingCart/substractOne", item);
	}

	function svuotaCarrello() {
		store.dispatch("shoppingCart/resetCart");
		currentCart.value = {
			n_items: 0,
			tot_price: 0,
			has_promo: false,
			discount: 0,
			items: [],
			payment_condition: "",
			customer: {
				name: "",
				surname: "",
				azienda: "",
				id: 0,
			},
		};
	}
</script>

<style lang="scss" scoped>
	.page {
		background: var(--client-ground1-color) !important;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: calc(100vh - 230px);
		padding-top: 1rem;
	}

	ion-icon {
		padding-top: 5px;
		font-size: 20px;
	}

	.counter {
		min-width: 75px;
		height: 25px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0px 0.7rem;
		background: var(--ion-color-secondary);
		border-radius: var(--ion-border-radius);
	}

	.resume {
		width: 95%;
		margin: 0 auto 1rem;
		padding: 1rem;
		background: var(--client-ground2-color) !important;
		border-radius: 25px !important;
		color: var(--text-color-dark);

		.item {
			width: 100%;
			margin: auto;
			font-size: 14px;
			display: grid;
			grid-template-columns: 1fr 5fr 3fr 3fr;
			align-items: center;
			margin-bottom: 1rem;
		}

		.message {
			background: var(--client-ground1-color);
			padding: 5px 15px;
			width: 100%;
			display: flex;
			justify-content: space-between;
		}

		.dettagli-cliente {
			display: block;
		}
		.totali-parziali {
			min-width: 50%;
			display: flex;
			flex-direction: column;
			text-align: end;

			font-weight: 500;
		}

		.totale-finale {
			font-size: 18px;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			padding-top: 2rem;
			font-weight: 500;
		}
	}

	.bottoni-finali {
		width: 80%;
		margin: auto;
	}
</style>
